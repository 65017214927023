import { ContentSummaryProps } from "common/components/ContentSummary"
import dynamic from "next/dynamic"
import { usePageProps } from "common/hooks/data/usePageProps"
import { EditPageProps } from "pages/edit/index.page"
import { MostReadView } from "./MostRead.view"

const MostReadEdit = dynamic<MostReadProps>(() =>
  import("modules/editor/blocks/MostRead/MostRead.edit").then((mod) => mod.MostReadEdit),
)

export interface MostReadProps {
  contentSummaries?: ContentSummaryProps[]
  regionCode?: string
  filterByCommissioningRegion?: boolean
  language?: string
  pageSlug?: string
}

export const MostRead = (props: MostReadProps) => {
  const { editing } = usePageProps<EditPageProps>()
  const MostReadComponent = editing ? MostReadEdit : MostReadView

  return <MostReadComponent {...props} />
}
