import { ContentSummary, ContentSummaryVariant } from "common/components/ContentSummary"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { Heading } from "modules/editor/blocks/Heading"
import { imageSizes } from "common/lib/imageSizes"
import { MostReadProps } from "./MostRead"
import clsx from "classnames"

/**
 * The most read component is rendered as a ordered list of content summaries
 * in a 2 column grid for larger devices and 1 column for smaller devices.
 */
export const MostReadView = ({ contentSummaries }: MostReadProps) => {
  const t = useTranslations("blocks.most_read")

  if (!contentSummaries || contentSummaries.length === 0) {
    return null
  }

  const numRows = Math.ceil(contentSummaries.length / 2)
  return (
    <section className="mb-4 mt-12">
      <div
        role="list"
        className={clsx("grid gap-4 sm:gap-6 md:grid-flow-col", {
          "md:grid-rows-[repeat(1,auto)]": numRows === 1,
          "md:grid-rows-[repeat(2,auto)]": numRows === 2,
          "md:grid-rows-[repeat(3,auto)]": numRows === 3,
        })}
      >
        {contentSummaries.map((contentSummary, index) => (
          <div key={index} className="flex justify-between gap-2 border-t border-gray-200 pt-4">
            <div className="break-normal font-serif text-2xl italic">{index + 1}.</div>

            <ContentSummary
              {...contentSummary}
              /* eslint-disable-next-line tailwindcss/no-custom-classname */
              className="most-read-block"
              variant={ContentSummaryVariant.BaseWide}
              imageSizes={imageSizes({ lg: 0.125, sm: 0.25 })}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
